export class TokenStorage {
  static LOCAL_STORAGE_CURRENTUSER = 'currentUser'
  static LOCAL_STORAGE_AUTHUSER = 'authUser'
  static LOCAL_STORAGE_MIMIC_ID = 'mimic_id'

  static LOCAL_STORAGE_TOKEN = 'token'
  static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token'

  static isAuthenticated () {
    return this.getToken() !== null
  }

  static storeUser (user) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_CURRENTUSER, user)
  }

  static storeAuthUser (user) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_AUTHUSER, user)
  }

  static storeMimicId (id) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_MIMIC_ID, id)
  }

  static storeToken (token) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token)
  }

  static storeRefreshToken (token) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, token)
  }

  static clear () {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_CURRENTUSER)
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_AUTHUSER)
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_MIMIC_ID)
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN)
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN)
  }

  static getCurrentUser () {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_CURRENTUSER)
  }

  static getAuthUser () {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_AUTHUSER)  
  }

  static getMimicId () {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_MIMIC_ID)  
  }

  static removeMimicId () {
    return localStorage.removeItem(TokenStorage.LOCAL_STORAGE_MIMIC_ID)  
  }

  static getCurrentUserName () {
    const currentuser = JSON.parse(TokenStorage.getCurrentUser())
    return currentuser.username
  }

  static getRefreshToken () {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN)
  }

  static getToken () {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN)
  }

  static getPermissions () {
    const currentuser = JSON.parse(TokenStorage.getCurrentUser())
    return currentuser.permissions || []
  }
}
