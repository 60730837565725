import { authenticationService } from '@/_services'

export const requestOptions = {
  get (url, body) {
    return {
      url: url,
      method: 'GET',
      ...headers(),
      params: body
    }
  },
  post (url, body) {
    return {
      url: url,
      method: 'POST',
      ...headers(),
      data: JSON.stringify(body)
    }
  },
  uploadFile (url, body) {
    const currentUser = authenticationService.currentUserValue || {}
    const authHeader = currentUser.token ? { Authorization: 'Bearer ' + currentUser.token } : {}
    
    var header = {
      headers: {
        ...authHeader
      }
    }

    return {
      url: url,
      method: 'POST',
      header,
      data: body
    }
  },
  patch (url, body) {
    return {
      url: url,
      method: 'PATCH',
      ...headers(),
      data: JSON.stringify(body)
    }
  },
  put (url, body) {
    return {
      url: url,
      method: 'PUT',
      ...headers(),
      data: JSON.stringify(body)
    }
  },
  delete (url) {
    return {
      url: url,
      method: 'DELETE',
      ...headers()
    }
  }
}

function headers () {
  const currentUser = authenticationService.currentUserValue || {}
  const authHeader = currentUser.token ? { Authorization: 'Bearer ' + currentUser.token } : {}
  return {
    headers: {
      'Content-Type': 'application/json',
      ...authHeader
    }
  }
}
