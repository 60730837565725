import { TokenStorage } from './token-storage'
import createAuthRefreshInterceptor from './axios-auth-refresh'
import { apiService, authenticationService } from '@/_services'
import axios from 'axios'

export function axiosSetUp () {
  const token = TokenStorage.getRefreshToken()
  const user = TokenStorage.getAuthUser()
  const mimic_id = TokenStorage.getMimicId()

  // only attach the bearer token if we're logged in
  if (token && user) {
    axios.interceptors.request.use(function (config) {

      config.headers.Authorization = 'Bearer ' + TokenStorage.getToken()      
      // send the mimic_id if we've got one stored
      if (mimic_id) {
        config.headers.mimic_user_id = mimic_id
      }

      return config
    })
  }

  const refresh = failedRequest => {
    if (failedRequest.response.status == 401 || failedRequest.response.status == 403) {
      const token = TokenStorage.getRefreshToken()
      return apiService.post('api/token/refresh/', { refresh: token })
      .then((response) => {
          console.log('REFRESH ERROR 1', response)
          const token = response.data.access
          TokenStorage.storeToken(token)
          authenticationService.setUserToken(token)
          failedRequest.response.config.headers.Authorization = 'Bearer ' + token
          return Promise.resolve(response)
      })
      .catch(error => {
        console.log('REFRESH ERROR 2', error)
        if (error.response.status == 401 || error.response.status == 403) {
          TokenStorage.clear()
          failedRequest.response.config.headers.Authorization = ''
          window.location.href = '/'
        } else {
          return Promise.reject(error)
        }
      })
    }

    // only try to refreh if we have a user but no token
    if (!token && user) {
      return Promise.reject(new Error('Refresh token not found'))
    }
  }

  if (token && user) {
    createAuthRefreshInterceptor(axios, refresh, {
      statusCodes: [400, 401, 403]
    })
  }

}
