import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGtag, {
  config: {
      id: 'G-7CSYY5T8FR',
      params: {
          send_page_view: false
      }
  }
}, router)

new Vue({
  router,
  render: h => h(App)
  // TODO: change to app
}).$mount('#portal-app')
