<template>
  <div id="app">
    <head>
      <link rel="preconnect" href="https://fonts.gstatic.com">
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat">
      <link rel="preconnect" href="https://use.typekit.net">
      <link rel=“stylesheet” href=“https://use.typekit.net/wpp4dra.css”>
    </head>

    <b-navbar variant="portal-secondary" style="display: flex; justify-content: center; text-align: center">
      The content of this portal may not be sold, reproduced, or distributed without B-Unlimited's written permission.
    </b-navbar>

    <b-navbar toggleable="lg" type="dark" :variant="mimic_id ? 'portal-secondary' : 'portal-primary'" sticky>
        
        <b-navbar-brand id="b-logo" :to="'/'" >
          <img id="true-logo" :src="require('../src/assets/logos/portal_logo_icon.png')" class="d-inline-block align-top"/>
          <span class="ml-5">
            B-Unlimited Portal
          </span>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse v-if="current_user" id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item exact-active-class="active" :to="'/'" >My Orders</b-nav-item>
            <b-nav-item exact-active-class="active" :to="'search'">Design Search</b-nav-item>
            <b-nav-item exact-active-class="active" :to="'top-products'">Top Products</b-nav-item>
            <b-nav-item exact-active-class="active" v-if="start_order_enabled" :to="'new-deal'">Start Order</b-nav-item>
            <b-nav-item exact-active-class="active" @click="openPinterest()">Pinterest</b-nav-item>
            <b-nav-item exact-active-class="active" @click="openFeaturedArtStyles()">Featured Art Styles</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em v-if="mimic_id"><i class="fa-solid fa-mask"></i> Mimicking as: </em>
                <em>
                  <span>
                    {{current_user.first_name}}
                  </span>
                </em>
              </template>
              
              <span v-if="mimic_id">
                <b-dropdown-item :to="'profile'">Profile</b-dropdown-item>
                <b-dropdown-item v-b-modal.mimic-modal @click="getUsers()">Mimic Different User</b-dropdown-item>
                <b-dropdown-item @click="dropMimic()">Exit Mimic</b-dropdown-item>
              </span>

              <span v-else>
                <b-dropdown-item :to="'profile'">Profile</b-dropdown-item>
                <b-dropdown-item v-if="auth_user.permissions && auth_user.permissions.includes('portal.add_user')" v-b-modal.mimic-modal @click="getUsers()">Mimic A User</b-dropdown-item>
                <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
              </span>

            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
    <br/>

    <b-modal id="mimic-modal" centered size="lg" hide-footer>
      <b-container>
        <b-row>
          <b-col cols="9">
            <ListSearch
              v-if="mimic_options"
              v-model="selected_mimic"
              :id="'mimic-select'"
              :label="'Choose a User to Mimic'"
              :label_icon_class="'fa-solid fa-mask'"
              :options="mimic_options"
              :option_text_field="'full_name'"
              :option_value_field="'id'"
            ></ListSearch>
          </b-col>
          <b-col align-self="end">
            <b-button variant="portal-secondary" @click="activateMimic()">
              Select User
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <div id="page-container">
      <!-- BODY -->
      <b-container id="content-wrap">
        <router-view/>
      </b-container>

      <!-- FOOTER -->
      <div id="footer" class="d-flex justify-content-center">
        © 2023 B-Unlimited All Rights Reserved.
      </div>
    </div>

  </div>

</template>

<script>
import { TokenStorage } from '@/_helpers/token-storage'
import { authenticationService, apiService } from '@/_services'
import ListSearch from './components/ListSearch.vue'

export default {
  components: {
    ListSearch
  },
  data() {
    return {
      current_user: '',
      auth_user: '',
      selected_mimic: {
        'person': {
          'id': ''
        }
      },
      mimic_options: [],
      mimic_id: '',
      start_order_enabled: false,
    }
  },
  mounted() {
    this.current_user = JSON.parse(TokenStorage.getCurrentUser())
    this.auth_user = JSON.parse(TokenStorage.getAuthUser())
    this.mimic_id = JSON.parse(TokenStorage.getMimicId())
    this.set_start_order()
  },
  methods: {
    // Start Order is only Enabled for PC Users, and the lowest pc resolution is 800x600
    set_start_order(){
      if (window.screen.width > 799  && window.screen.height > 599)
      {
        this.start_order_enabled = true
      }
    },
    logout() {
      authenticationService.logout()
      window.location.reload()
    },
    openPinterest(){
      window.open("https://www.pinterest.com/b_unlimitedgreek/_saved/", '_blank').focus()
    },
    openFeaturedArtStyles(){
      window.open("https://www.b-unlimited.com/pages/featured-art-styles", "_blank").focus()
    },
    getUsers() {
        apiService.get('api/users')
        .then(response => {
            this.mimic_options = response.data
        })
    },
    activateMimic() {
      if (this.selected_mimic && this.selected_mimic.id) {

        // add selected_mimic_id to token storage
        TokenStorage.storeMimicId(this.selected_mimic.id)

        var params = {portal_user_id: this.selected_mimic.id}
        apiService.get('api/users', params)
        .then(response => {
          // store the mimic user in currentUser
          var mimic_user = JSON.stringify(response.data)
          TokenStorage.storeUser(mimic_user)
        })
        setTimeout(() => {  window.location.reload() }, 300);
      }
    },
    dropMimic() {
      if (this.mimic_id) {
        // remove the mimic_id
        TokenStorage.removeMimicId()

        // switch currentUser back to match Auth user
        TokenStorage.storeUser(TokenStorage.getAuthUser())
        window.location.reload()
      }
    }
  }
}
</script>

<style>
@import url("https://use.typekit.net/wpp4dra.css");
#true-logo {
  height: 56px;
  position: absolute !important;
  left: 0;
  top:0;
}

/* footer positioning */
#page-container {
  position: relative;
  min-height: 90vh;
}

#content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}

#footer {
  position: absolute;
  margin-top: 10%;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}

/* app overall */
body {
  font-family: "Montserrat", sans-serif;
}

/* headers */
h1, h2, h3, h4, h5, h6 {
  font-family: "vinyl", sans-serif !important; 
  text-transform: uppercase;
}

/* navbar */
.navbar {
  font-family: "Montserrat", sans-serif !important;   
  text-transform: uppercase;
}

#b-logo {
  font-family: vinyl, sans-serif;
  text-transform: uppercase;
}

/* PORTAL COLORS */
/* Portal consists of the following 4 main colors: */
/* 1. portal-black #383F4A  (BLACK)     */
/* 2. portal-primary #3FB2B2 (BLUE)     */
/* 3. portal-secondary #E5625C (RED)    */
/* 4. portal-highlight #EFBE45 (YELLOW) */

/* 1. PORTAL BLACK */
.bg-portal-black {
  background-color: #383F4A !important;
}
.btn-portal-black {
  font-family: "Montserrat", sans-serif !important; 
  background: #383F4A !important;
  border:  #383F4A !important;
  color: white;
  border-radius: 0px;
  text-transform: uppercase;
}
.btn-portal-black:hover{
  color: white;
}
.btn-outline-portal-black.active {
  font-family: "Montserrat", sans-serif !important; 
  background: #383F4A !important;
  color: white;
  text-transform: uppercase;
}
.btn-outline-portal-black.active:hover {
  color: white;
}

.btn-outline-portal-black {
  font-family: "Montserrat", sans-serif !important; 
  border: 1px solid #383F4A !important;
  color: #383F4A;
  border-radius: 3px;
  text-transform: uppercase;
}
.btn-outline-portal-black:hover {
  color: #383F4A;
}


/* 2. PORTAL PRIMARY */
.bg-portal-primary {
  background: #3FB2B2 !important;
}
.btn-portal-primary {
  background: #3FB2B2 !important;
}
.page-item.active .page-link {
  background: #3FB2B2 !important;
  border: #3FB2B2 !important;
  color:white !important;
}
/* paginate buttons */
.page-link {
  color: #3FB2B2 !important;
}


/* 3. PORTAL SECONDARY */
.bg-portal-secondary {
  background: #E5625C !important;
}
/* action buttons */
.btn-portal-secondary {
  font-family: "Montserrat", sans-serif !important; 
  background: #E5625C !important;
  border:  #E5625C !important;
  color: white;
  border-radius: 0px;
  text-transform: uppercase;
}
.btn-portal-secondary:hover{
  color: white;
}
.bg-portal-secondary {
  font-family: "Montserrat", sans-serif !important; 
  background: #E5625C !important;
  color: white;
  text-transform: uppercase;
}
.alert-portal-secondary {
  font-family: "Montserrat", sans-serif !important; 
  background: #E5625C !important;
  color: white;
  text-transform: uppercase;
}
.btn-secondary {
  text-transform: uppercase;
  border-radius: 0px;
}

.b-toast-portal-secondary .toast-header{
  background: #E5625C !important;
  color: white;
}


/* 4. PORTAL HIGHLIGHT */
.bg-portal-highlight {
  background: #EFBE45 !important;
}
.bg-secondary {
  font-family: "Montserrat", sans-serif !important; 
  background: #EFBE45 !important;
  text-transform: uppercase;
}


/* MISC */
.page-item.disabled .page-link {
  color:gray !important;
}

</style>