import { BehaviorSubject } from 'rxjs'
import { apiService } from '@/_services'
import { TokenStorage } from '../_helpers/token-storage'
// import jwtDecode from 'jwt-decode'
const currentUserSubject = new BehaviorSubject(JSON.parse(TokenStorage.getCurrentUser()))
const authUserSubject = new BehaviorSubject(JSON.parse(TokenStorage.getAuthUser()))

const sessionActiveSubject = new BehaviorSubject(false)

export const authenticationService = {
  login,
  logout,
  setUserToken,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue () { return currentUserSubject.value },
  authUser: authUserSubject.asObservable(),
  get authUserValue () { return authUserSubject.value },
  sessionActive: sessionActiveSubject.asObservable(),
  get sessionActiveValue () { return sessionActiveSubject.value }
}

function removeUser () {
  TokenStorage.clear()
  currentUserSubject.next(null)
  sessionActiveSubject.next(false)
}

function setUser (user) {
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  TokenStorage.storeUser(JSON.stringify(user))
  currentUserSubject.next(user)
  sessionActiveSubject.next(true)
  return user
}

function setAuthUser (user) {
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  TokenStorage.storeAuthUser(JSON.stringify(user))
  authUserSubject.next(user)
  sessionActiveSubject.next(true)
  return user
}

function setUserToken () {
  const currentUser = authenticationService.currentUserValue || {}
  return setUser(currentUser)
}

function login (params) {
  return apiService.noAuth.post('api/token/', params)
    .then((response) => {
      const token = response.data.access
      TokenStorage.storeToken(token)
      TokenStorage.storeRefreshToken(response.data.refresh)

      const user_info = response.data.user_info
      
      setAuthUser(user_info)
      return setUser(user_info)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

function logout () {
  if (authenticationService.currentUserValue) {
    removeUser()
  }
}
