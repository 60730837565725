<template>
    <div>
        <label :for="`search-select-${id}`">
            <i v-if="label_icon_class" :class="`${label_icon_class}`"></i>
            {{label}}
        </label>
        <b-form-input v-model="selected_option" :list="`option-list-${id}`" id="`option-list-${id}`" @change="verifySelect()" @focus="clear()"></b-form-input>
        <b-form-datalist :id="`option-list-${id}`" :options="options" :text-field="option_text_field"></b-form-datalist>
    </div>
</template>

<script>
export default {
    name: 'ListSearch',
    data() {
      return {
        selected_option: '',
        selected_option_object: {}
      }
    },
    props: {
        // special prop that is linked to the parent's v-model.
        // Notes: the v-model on parent must be an object {}
        value: {
            type: Object,
            required: false
        },
        // pass in the name of the 'name' field on the option object 
        // (ie. object: {'id': 1, 'name': 'option 1'}), then option_text_field = 'name'
        // (ie. object: {'id': 1, 'label': 'label 1'}), then option_text_field = 'label'
        option_text_field: {
            type: String,
            required: true
        },
        // pass in the name of the 'value' field on the option object 
        // (ie. object: {'id': 1, 'name': 'option 1'}), then option_value_field = 'id'
        // (ie. object: {'value': 1, 'name': 'option 1'}), then option_value_field = 'value'
        option_value_field: {
            type: String,
            required: true
        },
        // pass in a unique identifier (prevents overlaps for multiple components on same parent)
        id: {
            type: String,
            required: true
        },
        // list of options to choose from [ {id: 1, label: "Option 1"} ]
        options: {
            type: Array,
            required: true
        },
        // the label for the Input
        label: {
            type: String,
            required: true
        },
        // expects a fontawesome class
        // https://fontawesome.com/
        label_icon_class: {
            type: String,
            required: false
        },
    },
    methods: {
        verifySelect() {
            var valid_option = this.options.find(option => option[this.option_text_field] === this.selected_option)
            if (valid_option == undefined) { 
                this.selected_option = ''
            }
            this.selected_option_object = valid_option
            this.value[this.option_value_field] = valid_option[this.option_value_field]
            this.value[this.option_text_field] = valid_option[this.option_text_field]
        },
        clear() {
            this.selected_option = ''
        }
    }
}
</script>

<style>

</style>