const defaults = { statusCodes: [401] }

function createAuthRefreshInterceptor (axios, refreshTokenCall, options = {}) {
  let refreshCall = null
  const id = axios.interceptors.response.use(
    (res) => res,
    (error) => {
    // Reject promise if the error status is not in options.ports or defaults.ports
      const statusCodes = options.statusCodes.length
        ? options.statusCodes
        : defaults.statusCodes
      if (
        !error.response ||
        (error.response.status &&
        statusCodes.indexOf(+error.response.status) === -1)
      ) {
        return Promise.reject(error)
      }

      // Token is already being refreshed.
      // Bind all this request until refreshTokenCall is resolved.
      if (refreshCall != null) {
        return refreshCall.then(() => axios(error.response.config))
      }

      // Remove the interceptor to prevent a loop
      // in case token refresh also causes the 401
      axios.interceptors.response.eject(id)

      refreshCall = refreshTokenCall(error)

      // Create interceptor that will bind all the others requests
      // until refreshTokenCall is resolved
      const requestQueueInterceptorId = axios.interceptors.request.use(
        (request) => refreshCall.then(() => Promise.resolve(request))
      )

      // When response code is 401 (Unauthorized), try to refresh the token.
      return refreshCall
        .then(() => {
          axios.interceptors.request.eject(requestQueueInterceptorId)
          return Promise.resolve(axios(error.response.config))
        })
        .catch((error) => {
          axios.interceptors.request.eject(requestQueueInterceptorId)
          return Promise.reject(error)
        })
        .finally(() => {
          createAuthRefreshInterceptor(axios, refreshTokenCall, options)
          refreshCall = null
        })
    }
  )
  return axios
}
export default createAuthRefreshInterceptor
