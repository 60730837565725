import Vue from 'vue'
import VueRouter from 'vue-router'
import { axiosSetUp } from '../_helpers/interceptors'
import { authenticationService } from '@/_services'
import { TokenStorage } from '../_helpers/token-storage'

Vue.use(VueRouter)

const routes = [
  {
    path: "/register/:invite_id",
    name: "Register",
    component: () => import("../views/PortalRegister.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/PortalLogin.vue"),
  },
  {
    path: "/reset-password/:uid/:token",
    name: "Password Reset",
    component: () => import("../views/PortalResetPassword.vue"),
  },
  {
    path: "/reset-password-email",
    name: "Reset Password",
    component: () => import("../views/PortalResetPasswordEmail.vue"),
  },
  {
    path: "/",
    alias: '/home',
    name: "Home",
    meta: { auth_required: true },
    component: () => import("../views/PortalHome.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth_required: true },
    component: () => import("../views/PortalProfile.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: { auth_required: true },
    component: () => import("../views/PortalSearch.vue"),
  },
  {
    path: "/new-deal",
    name: "Order",
    meta: { auth_required: true },
    component: () => import("../views/PortalNewDeal.vue"),
  },
  {
    path: "/top-products",
    name: "Top Products",
    meta: { auth_required: true },
    component: () => import("../views/TopProducts.vue"),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Portal | ${to.name}`

  // redirect to login page if not logged in and trying to access a restricted page
  const { auth_required } = to.meta
  const currentUser = authenticationService.currentUserValue
  const authUser = authenticationService.authUserValue
  const mimic = TokenStorage.getMimicId()

  console.debug('currentUser:', currentUser)
  console.debug('authUser:', authUser)
  console.debug('mimicID:',  mimic)

  if (auth_required) {
    console.log("Auth Requried for this page.")
    // check that the user is logged in. If not, redirect to login page.
    if (!currentUser ) {
      return next({ path: '/login', query: { returnUrl: to.path } })
    }
  }
  return next()
})

axiosSetUp()


export default router
